import { useEffect } from 'react';
import { useRouter } from 'next/router';

import useSearch from './useSearch';
import useGetNextRoute from './useGetNextRoute';

interface KeepSearchProps {
  setLarge: (large: boolean) => void;
}

const useKeepSearch = ({ setLarge }: KeepSearchProps): void => {
  const { asPath } = useRouter();
  const { search, lastUrl: url, setLastUrl, setSearch } = useSearch();
  const nextRoute = useGetNextRoute();

  useEffect(() => {
    if (asPath === url && search) {
      setLarge(true);
    } else {
      setLarge(false);
    }
  }, [asPath]);

  useEffect(() => {
    if (!nextRoute) return;
    if (!url && search && !asPath.includes(search)) {
      setLastUrl(asPath);
    }

    if (url && ![asPath, nextRoute].includes(url)) {
      setLastUrl(null);
      setSearch(null);
    }
  }, [nextRoute]);
};

export default useKeepSearch;

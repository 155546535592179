import { useEffect, useState } from 'react';
import { useRouter } from 'next/router';

const useGetNextRoute = (): string => {
  const router = useRouter();
  const [nextRoute, setNextRote] = useState<string>(null);

  useEffect(() => {
    const handleRouteChange = (url: string): void => {
      setNextRote(url);
    };

    router.events.on('routeChangeStart', handleRouteChange);

    return (): void => {
      router.events.off('routeChangeStart', handleRouteChange);
    };
  }, []);

  return nextRoute;
};

export default useGetNextRoute;

import React, { useCallback, useEffect, useReducer, useState } from 'react';
import { useRouter } from 'next/router';
import { useReactiveVar } from '@apollo/client';
import Scrollbars from 'react-custom-scrollbars';
import dynamic from 'next/dynamic';

import {
  getFilterFix,
  initEventScroll,
  setAreaByName,
  validateIfUserSusbcribeToNewsLetter,
} from './utils/mainLayout.util';
import useScrollBarPage from '../../hooks/useScrollbarPage';
import useFiltersAndOrder from '../../hooks/useFiltersAndOrder';
import { TypeActionMainLayout } from '../../reducer/mainLayout/actionMainLayout';
import reducerMainLayout from '../../reducer/mainLayout/reducerMainLayout';
import useKeepSearch from '../../hooks/useKeepSearch';
import Scrollbar from '../../components/common/layout/Scrollbar';
import { SCREEN_SIZES } from '../../utils/constants';
import useWindowSize from '../../hooks/useWindowSize';
import { Area, useGetAreasLazyQuery } from '../../graphqlTypes';
import userReactiveVar, { useReactiveVariableUser } from '../../hooks/useReactiveVariableUser';
import useIsMounted from '../../hooks/useIsMounted';
import AlertAPI from '../../components/alerts/AlertAPI';
import LoginCookiesControl from './LoginCookiesControl';
import {
  NameStorage,
  removeDataInStorage,
  setDataInStorage,
} from '../../utils/services/localStorage.service';
import { useScrollBar } from '../../hooks/useScrollBar';
import useSearch from '../../hooks/useSearch';

const Header = dynamic(() => import('../Header/Header'));
const Footer = dynamic(() => import('../Footer/Footer'));
const NavResponsive = dynamic(() => import('../NavResponsive'));
const Search = dynamic(() => import('../../components/common/Search/Search'));

interface PropsMainLayout {
  areasOpen?: boolean;
  fullScreen?: boolean;
  hideSearch?: boolean;
  children: JSX.Element;
  category?: string;
  className?: string;
}

const MainLayout = ({
  fullScreen,
  hideSearch,
  children,
  areasOpen,
  category,
  className,
}: PropsMainLayout): JSX.Element => {
  useReactiveVariableUser();
  const isMounted = useIsMounted();
  const user = useReactiveVar(userReactiveVar);
  const { filterOpen: openFilter } = useFiltersAndOrder();
  const router = useRouter();
  const { documentWidth } = useWindowSize();
  const [_fixedFilterState] = useState<number>(0);
  const { scrollBar } = useScrollBar();
  const { search: searchField, setSearch } = useSearch();
  const { isTheActualPageSave } = useScrollBarPage();

  useEffect(() => {
    if (window && window.innerWidth <= 768) {
      document.documentElement.style.setProperty('--vh', `${window.innerHeight * 0.01}px`);
      const windowsOnResize = window.onresize;
      window.onresize = (ev: UIEvent): void => {
        document.documentElement.style.setProperty('--vh', `${window.innerHeight * 0.01}px`);
        if (windowsOnResize?.apply) windowsOnResize.apply(window, [ev]);
      };
      return (): void => {
        window.onresize = windowsOnResize;
      };
    }
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    return (): void => {};
  }, []);

  useEffect(() => {
    if (!isTheActualPageSave()) {
      scrollBar?.scrollToTop();
    }
  }, [router.asPath]);

  const [state, setState] = useReducer(reducerMainLayout, {
    sidebarShown: false,
    fullScreen,
    isSearching: false,
    popUp: null,
    scroll: 'up',
    fixed: false,
    hideMainSection: false,
    searchScrollTop: false,
    areaOfInterest: null,
    limit: 0,
    keys: null,
    abcActive: true,
    large: false,
    busqueda: false,
  });

  const handleNewsletterPopUp = (): void => {
    if (!user) {
      setState({
        type: TypeActionMainLayout.set_state,
        mainLayout: { showModal: false },
      });
    }

    const isNotNewUserOrNoSuscribtion = validateIfUserSusbcribeToNewsLetter(user?.createdAt);

    if (!user?.newsletterActive && isNotNewUserOrNoSuscribtion) {
      setState({
        type: TypeActionMainLayout.set_state,
        mainLayout: { showModal: true },
      });
      return;
    }

    setState({
      type: TypeActionMainLayout.set_state,
      mainLayout: { showModal: false },
    });
  };

  useEffect(() => {
    handleNewsletterPopUp();
  }, [user]);

  const setLarge = useCallback((large?: boolean): void => {
    setState({
      type: TypeActionMainLayout.set_state,
      mainLayout: { large, fullScreen: false, isSearching: false },
    });
  }, []);

  useKeepSearch({ setLarge });

  const [getAreasLazyQuery, { data: queryGetAreas }] = useGetAreasLazyQuery({
    fetchPolicy: 'cache-first',
  });

  const getScrollElement = (): Scrollbars => {
    if (!scrollBar) {
      return null;
    }

    return scrollBar;
  };

  const setCloseToEnd = (scrollbarValues: Scrollbars): void => {
    if (documentWidth > SCREEN_SIZES.MAX_TABLET) return;

    const { top } = scrollbarValues.getValues();

    if (top > 0.96) {
      if (state.closeToEnd) return;

      setState({
        type: TypeActionMainLayout.set_state,
        mainLayout: { closeToEnd: true },
      });

      return;
    }

    if (top < 0.8 && state.closeToEnd) {
      setState({
        type: TypeActionMainLayout.set_state,
        mainLayout: { closeToEnd: false },
      });
    }
  };

  const setFixed = (scrollTop: number, fixedFilter: number): void => {
    if (scrollTop > fixedFilter && !state.fixed) {
      setState({
        type: TypeActionMainLayout.set_state,
        mainLayout: { fixed: true },
      });

      return;
    }

    if (scrollTop < fixedFilter && state.fixed) {
      setState({
        type: TypeActionMainLayout.set_state,
        mainLayout: { fixed: false },
      });
    }
  };

  const setSearchScrollTop = (scrollTop: number): void => {
    if (scrollTop === 0 && state.searchScrollTop) {
      setState({
        type: TypeActionMainLayout.set_state,
        mainLayout: { searchScrollTop: false },
      });

      return;
    }

    if (scrollTop > 0 && !state.searchScrollTop) {
      setState({
        type: TypeActionMainLayout.set_state,
        mainLayout: { searchScrollTop: true },
      });
    }
  };

  const setScrollAndHide = (scrollTop: number, lastScrollTop: number): void => {
    let isHidden = false;
    if (scrollTop > lastScrollTop) {
      if (isHidden) return;

      isHidden = true;
      setState({
        type: TypeActionMainLayout.set_state,
        mainLayout: { scroll: 'down', hide: !state.hide },
      });

      isHidden = false;

      return;
    }

    if (state.scroll !== 'up') {
      setState({
        type: TypeActionMainLayout.set_state,
        mainLayout: { scroll: 'up', hide: !state.hide },
      });

      isHidden = false;
    }
  };

  const onScroll = (stop?: boolean): boolean => {
    const scrollbarValues = getScrollElement();

    if (!scrollbarValues || !isMounted() || stop) {
      return null;
    }

    initEventScroll();

    // Expandir/Colapsar el footer para móviles
    setCloseToEnd(scrollbarValues);

    const scrollTop = scrollbarValues.getScrollTop();
    const _fixedFilter = getFilterFix() || _fixedFilterState;
    let _lastScrollTop = 0;

    setFixed(scrollTop, _fixedFilter);

    setScrollAndHide(scrollTop, _lastScrollTop);

    _lastScrollTop = scrollTop;
    setSearchScrollTop(scrollTop);

    return true;
  };

  const toggleSidebar = (): void => {
    setState({
      type: TypeActionMainLayout.set_state,
      mainLayout: { sidebarShown: !state.sidebarShown },
    });
  };

  const updateItemCart = useCallback((totalCart: number): void => {
    setState({
      type: TypeActionMainLayout.set_state,
      mainLayout: { totalCart },
    });
  }, []);

  const changeSearching = useCallback((isSearching?: boolean): void => {
    setState({
      type: TypeActionMainLayout.set_state,
      mainLayout: { isSearching },
    });
  }, []);

  const removeAreaOfInterest = (event?: Event): void => {
    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }

    removeDataInStorage(NameStorage.areaOfInterest);
    removeDataInStorage(NameStorage.areaOfInterestName);

    setState({
      type: TypeActionMainLayout.set_state,
      mainLayout: { areaOfInterest: null, areaOfInterestName: null },
    });
  };

  const search = (value: string): void => {
    const isSearching = value?.length > 2;
    const fadeIn = value?.length > 2;

    setState({
      type: TypeActionMainLayout.set_state,
      mainLayout: { isSearching, fadeIn },
    });

    setSearch(value);
  };

  const goToNewsletterSubscription = (): void => {
    setDataInStorage(NameStorage.noSubscriptionToNewsletter, 'true');
    router.push('/cuenta');
  };

  const hideNewsletterPopUp = (): void => {
    setDataInStorage(NameStorage.noSubscriptionToNewsletter, 'true');
    setState({
      type: TypeActionMainLayout.set_state,
      mainLayout: { showModal: false },
    });
  };

  const setSearching = useCallback((value: string): void => {
    setSearch(value);
  }, []);

  const setKeyActives = useCallback((keys: unknown): void => {
    setState({
      type: TypeActionMainLayout.set_state,
      mainLayout: { keys },
    });
  }, []);

  const setAreaOfInterest = (
    _idArea: number,
    redirect: boolean,
    _areaName: string,
    name: string,
  ): void => {
    setState({
      type: TypeActionMainLayout.set_state,
      mainLayout: {
        areaOfInterestName: name,
        isSearching: false,
        fadeIn: false,
        fullScreen: false,
      },
    });

    if (redirect) {
      router.back();
    }
  };

  const showLogin = useCallback((): void => {
    setState({
      type: TypeActionMainLayout.set_state,
      mainLayout: {
        popUp: 'login',
      },
    });
  }, []);

  const setFullScreen = (propState: boolean, value?: string): void => {
    if (!propState) {
      setState({
        type: TypeActionMainLayout.set_state,
        mainLayout: {
          fullScreen: false,
          isSearching: false,
          fadeIn: false,
        },
      });

      setSearch(null);

      return;
    }

    setState({
      type: TypeActionMainLayout.set_state,
      mainLayout: {
        fullScreen: propState,
        large: true,
      },
    });

    search(value);
  };

  const getAreaOfInterest = (): Area => {
    const area = state.areaOfInterest;
    const { areas } = state;

    if (!area || !areas) {
      return null;
    }

    return areas.find((x) => x.id.toString() === area.toString());
  };

  const closeModalUser = useCallback((): void => {
    setState({
      type: TypeActionMainLayout.set_state,
      mainLayout: {
        popUp: null,
      },
    });
  }, []);

  const onScrollStop = useCallback((): void => {
    setState({
      type: TypeActionMainLayout.set_state,
      mainLayout: {
        hide: false,
      },
    });
  }, []);

  const setAreaOfInterestName = (areaName: string, areaId: number, redirect = true): void => {
    const { areas } = state;
    if (!areas) {
      setState({
        type: TypeActionMainLayout.set_state,
        mainLayout: {
          areaName,
          areaId,
          redirect,
        },
      });
      getAreasLazyQuery();

      return;
    }
    setAreaByName(areaId, areas, areaName);
  };

  useEffect(() => {
    if (!queryGetAreas?.getAreas) return;
    setAreaByName(state.areaId, queryGetAreas?.getAreas, state.areaName);
    setState({
      type: TypeActionMainLayout.set_state,
      mainLayout: {
        areas: queryGetAreas?.getAreas,
      },
    });
  }, [queryGetAreas?.getAreas]);

  return (
    <section
      className={`mainLayout ${
        openFilter && documentWidth >= SCREEN_SIZES.MAX_TABLET ? 'filtersOpened' : ''
      }${openFilter && documentWidth <= SCREEN_SIZES.MAX_TABLET ? 'overflowHidden' : ''}${
        state.sidebarShown ? ' sidebar' : ''
      }${state?.fullScreen ? ' fullscreen' : ''}${hideSearch ? ' noSearch' : ''}${
        state.isIOSMobile ? ' ios-bottomBar' : ''
      } noCart ${!!className && className}`}
    >
      <Header
        getScrollElement={getScrollElement}
        buscando={state.isSearching ? state.isSearching : false}
        toggleSidebar={toggleSidebar}
        setSearching={setSearching}
        showLoginFn={showLogin}
        setFullScreen={setFullScreen}
        getAreaOfInterest={getAreaOfInterest}
        large={state.large}
        setLarge={setLarge}
        hidden={(state.scroll === 'down' || openFilter) && documentWidth <= SCREEN_SIZES.MAX_TABLET}
        fullScreen={state.fullScreen}
        removeAreaOfInterest={removeAreaOfInterest}
        sidebar={state.sidebarShown}
        user={user}
        searchScrollTop={state.searchScrollTop}
        areaOfInterest={state.areaOfInterest}
        keys={state.keys}
        search={searchField}
        results
        changeSearching={changeSearching}
        category={category}
      />

      <div className={`searchBarMobile ${state?.fullScreen ? 'fullscreen' : ''}`}>
        {documentWidth <= SCREEN_SIZES.MIN_DESK && (
          <Search
            setFullScreen={setFullScreen}
            fullScreen={state.fullScreen}
            tamagno={documentWidth <= SCREEN_SIZES.MIN_DESK}
            buscando={state.isSearching ? state.isSearching : false}
            changeSearching={changeSearching}
            toggleSearch={changeSearching}
          />
        )}
      </div>

      <Scrollbar id="scrollBarMainLayout" onScroll={onScroll} onScrollTop={onScrollStop}>
        <div
          className={`mainSection${state.isSearching ? ' hide' : ''} ${
            areasOpen ? 'areasOpen' : ''
          }`}
        >
          {React.Children.map(children, (child) =>
            React.cloneElement(child, {
              setSearching,
              updateItemCart,
              setAreaOfInterest,
              setAreaOfInterestName,
              fixed: state.fixed,
              config: user,
              areaOfInterest: state.areaOfInterest,
              areaOfInterestName: state.areaOfInterestName,
              mainLayout: true,
              searching: state.isSearching,
              setFullScreen,
              setKeyActives,
              abcActive: state.abcActive,
              getAreaOfInterest,
              hidden:
                (state.scroll === 'down' || openFilter) &&
                documentWidth <= SCREEN_SIZES.MAX_TABLET &&
                state.hide &&
                state.fixed,
            }),
          )}
        </div>
        {state.fullScreen ? null : <Footer />}
      </Scrollbar>

      {state.sidebarShown && (
        <NavResponsive
          removeAreaOfInterest={removeAreaOfInterest}
          areaOfInterest={state.areaOfInterest}
          toggleSidebar={toggleSidebar}
          showLogin={showLogin}
        />
      )}

      <LoginCookiesControl closeModalUser={closeModalUser} popUp={state.popUp} />

      {state.showModal && (
        <AlertAPI
          title="Hemos visto que no estás dado de alta en nuestra newsletter"
          message="¿Te gustaría suscribirte?"
          options={{
            callBack: null,
            scroll: false,
            cancel: true,
            confirm: true,
            confirmText: 'Aceptar',
            cancelText: 'No volver a mostrar',
            confirmFn: goToNewsletterSubscription,
            cancelFn: hideNewsletterPopUp,
          }}
        />
      )}
    </section>
  );
};

export default React.memo(MainLayout);

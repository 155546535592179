import dynamic from 'next/dynamic';
import React from 'react';

const Login = dynamic(() => import('../../components/common/auth/Login'));
interface LoginCookiesControlProps {
  popUp: 'login' | null;
  closeModalUser: () => void;
}

const LoginCookiesControl: React.FC<LoginCookiesControlProps> = ({ closeModalUser, popUp }) => {
  if (popUp === 'login') {
    return <Login closeModalUser={closeModalUser} />;
  }

  return null;
};

export default LoginCookiesControl;

import React from 'react';
import { AlertApiProps } from './modal.model';
import ModalAlert from './ModalAlert';

const AlertAPI = ({ title, message, options }: AlertApiProps): JSX.Element => {
  return (
    <ModalAlert heading={title} message={message} options={options}>
      {options.children as React.ReactElement}
    </ModalAlert>
  );
};

export default AlertAPI;

import React, { createRef, useEffect } from 'react';
import { positionValues, Scrollbars } from 'react-custom-scrollbars';
import { useScrollBar } from '../../../hooks/useScrollBar';
import constants from '../../../utils/constants';
import useScrollBarPage from '../../../hooks/useScrollbarPage';

const { hideScrollbars, pixelToLoad } = constants;
interface ScrollbarProps {
  children?: React.ReactNode | React.ReactChildren;
  id?: string;
  onScroll?: (stop?: boolean) => void;
  onScrollTop?: () => void;
}

const Scrollbar = ({ children, onScroll, onScrollTop, id }: ScrollbarProps): JSX.Element => {
  const ref = createRef<Scrollbars>();
  const { setScrollBar } = useScrollBar();
  const { setScrollbarValues } = useScrollBarPage();

  useEffect(() => {
    if (!ref.current) return;
    setScrollBar(ref.current);
  }, [ref.current]);

  const handleOnScroll = (scrollValues: positionValues): void => {
    setScrollbarValues(scrollValues);

    const { scrollHeight, clientHeight, scrollTop } = scrollValues;
    const height = scrollHeight - clientHeight;
    const reachBottom = height && height - scrollTop <= pixelToLoad;

    if (reachBottom || !scrollTop) {
      if (onScroll) onScroll();
      return;
    }

    if (onScroll) onScroll(true);
  };

  return hideScrollbars ? (
    <div className="noScrollbars">{children}</div>
  ) : (
    <div className="scrollbarHolder">
      <Scrollbars
        id={id}
        ref={ref}
        onScrollStop={onScrollTop}
        onScrollFrame={handleOnScroll}
        universal
      >
        {children}
      </Scrollbars>
    </div>
  );
};

export default React.memo(Scrollbar);

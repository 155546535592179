import cookie from 'react-cookies';
import getSlug from 'speakingurl';

import { canUseDom } from '../../../utils/utils';
import {
  getDataFromStorage,
  NameStorage,
  setDataInStorage,
} from '../../../utils/services/localStorage.service';
import { Area } from '../../../graphqlTypes';

export const validateIfUserSusbcribeToNewsLetter = (userCreatedAt: string): boolean => {
  const noSubscriptionToNewsletter = getDataFromStorage<string>(
    NameStorage.noSubscriptionToNewsletter,
  );

  const today = new Date();
  const userCreationDate = new Date(userCreatedAt);
  userCreationDate?.setDate(userCreationDate?.getDate() + 1);
  return !noSubscriptionToNewsletter && new Date(today) > new Date(userCreationDate);
};

export const getFilterFix = (): number => {
  let offset = 0;

  offset +=
    document.getElementsByClassName('productsBanner ') &&
    document.getElementsByClassName('productsBanner ')[0]
      ? document.getElementsByClassName('productsBanner ')[0].clientHeight
      : 0;
  offset +=
    document.getElementsByClassName('toolsBlock') &&
    document.getElementsByClassName('toolsBlock')[0]
      ? document.getElementsByClassName('toolsBlock')[0].clientHeight
      : 0;

  return offset;
};

export const saveCookies = (): void => {
  const date = new Date();
  date.setTime(date.getTime() + 30 * 24 * 60 * 60 * 1000);
  cookie.save('cookieA', 'true', { path: '/', expires: date });
};

export const setAreaByName = (areaId: number, areas: Area[], areaName: string): void => {
  let area = areaId;

  if (!areaId) {
    areas.forEach((areaValue) => {
      if (getSlug(areaValue?.nameEs?.toLowerCase()) === getSlug(areaName?.toLowerCase())) {
        area = areaValue.id;
      }
    });
  }

  if (!area || !canUseDom()) return;

  const name = areas?.find((a) => a.id === area).nameEs;
  setDataInStorage(NameStorage.areaOfInterest, area);
  setDataInStorage(NameStorage.areaOfInterestName, name);
};

export const initEventScroll = (): void => {
  const customEvent = document.createEvent('Event');
  customEvent.initEvent('bp.scroll', true, true);
  document.dispatchEvent(customEvent);
};

import React, { JSXElementConstructor, ReactElement } from 'react';
import Icon from '../common/Icon';
import Button from '../common/forms/Button';
import PopUp from './PopUp';
import { ModalProps } from './modal.model';

interface ComponentProps {
  options: ModalProps;
  alert?: boolean;
  heading: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  message: ReactElement<{ ref: string }, string | JSXElementConstructor<any>>;
  children: JSX.Element | JSX.Element[];
}

const ModalAlert = ({ options, alert, message, heading }: ComponentProps): JSX.Element => {
  const cancelFn = (): void => {
    options.cancelFn();
  };

  const confirmFn = (): void => {
    options.confirmFn();
  };

  return (
    <PopUp modal>
      <div className={alert ? 'alert' : 'modal'}>
        {options.cancel ? <Icon onClick={cancelFn} className="close" /> : null}

        {heading ? <h2>{heading}</h2> : null}

        {options.prompt ? React.cloneElement(message, { ref: 'prompt' }) : <p>{message}</p>}

        {options.cancel || options.confirm ? (
          <div className="actionsHolder">
            {options.cancel && (
              <Button onClick={cancelFn} className="rounded bordered" iconLeft iconClass="close">
                {options.cancelText}
              </Button>
            )}

            <Button
              onClick={confirmFn}
              className="rounded bordered cta"
              iconRight
              iconClass="arrow-right"
            >
              {options.confirmText}
            </Button>
          </div>
        ) : null}
      </div>
    </PopUp>
  );
};

export default ModalAlert;

import { makeVar, useReactiveVar } from '@apollo/client';
import Scrollbars from 'react-custom-scrollbars';

export const scrollBarReactiveVar = makeVar<Scrollbars>(null);

interface ScrollBarHookReturn {
  setScrollBar: (newScrollBar: Scrollbars) => void;
  goToPositionInScroll: (position?: number) => void;
  scrollBar: Scrollbars;
}

export const useScrollBar = (): ScrollBarHookReturn => {
  const scrollBar = useReactiveVar(scrollBarReactiveVar);

  const setScrollBar = (newScrollBar: Scrollbars): void => {
    scrollBarReactiveVar(newScrollBar);
  };

  const goToPositionInScroll = (position = 0): void => {
    scrollBar?.scrollTop(position);
  };

  return {
    setScrollBar,
    goToPositionInScroll,
    scrollBar,
  };
};

import ActionMainLayout, { TypeActionMainLayout } from './actionMainLayout';
import { StateMainLayout } from '../../layouts/MainLayout/models/mainLayout.model';

const reducerMainLayout = (state: StateMainLayout, action: ActionMainLayout): StateMainLayout => {
  switch (action.type) {
    case TypeActionMainLayout.set_state: {
      return {
        ...state,
        ...action.mainLayout,
      };
    }

    default: {
      return state;
    }
  }
};

export default reducerMainLayout;

import { StateMainLayout } from '../../layouts/MainLayout/models/mainLayout.model';

export enum TypeActionMainLayout {
  'set_state',
}

interface ActionMainLayout {
  type: TypeActionMainLayout.set_state;
  mainLayout: Partial<StateMainLayout>;
}

export default ActionMainLayout;

import React from 'react';

const useIsMounted = (): (() => boolean) => {
  const ref = React.useRef(false);
  const [, setIsMounted] = React.useState(false);

  React.useEffect(() => {
    ref.current = true;
    setIsMounted(true);
    return (): void => {
      ref.current = false;
    };
  }, []);

  return (): boolean => ref.current;
};

export default useIsMounted;

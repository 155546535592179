import React from 'react';

interface ComponentProps {
  id?: string;
  alert?: boolean;
  scroll?: boolean;
  children: JSX.Element;
  modal?: boolean;
}

const PopUp = ({ id, alert, children, scroll }: ComponentProps): JSX.Element => {
  return (
    <div
      id={id}
      className={`popUp ${alert ? ' alert' : ' modal'} ${
        scroll ? 'scroll' : ''
      }`}
    >
      {children}
    </div>
  );
}

export default PopUp;
